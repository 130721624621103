import axios from 'axios';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import { GetStaticProps } from 'next/types';
import { ComponentProps, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FAQTabData } from 'components/Landing/FAQ';
import i18n from 'config/i18n';
import { ENABLED_AB_TESTING_PAGE_URLS, LINKS, REACT_APP_FAQ_WP_API_ENDPOINT } from 'constants/constants';
import LandingContainer from 'containers/LandingContainer';
import { botUserAgent, canonicalLink, languageAlternatesLinks } from 'utils/seo';

import { templateCategoriesApi } from '../api/templateCategories';
import { DEFAULT_LOCALE } from 'constants/languages';
import { OrganizationJsonLd } from '../components/seo/OrganizationJsonLd';
import Head from 'next/head';
import { deleteCookie } from '../utils/common';
import { getAbTestingScript } from 'utils/AbTestingScript';

const CACHE_TTL_SEC = process.env.CACHE_HTML_PAGE_TTL_SEC ? +process.env.CACHE_HTML_PAGE_TTL_SEC : 60;

export const getStaticProps: GetStaticProps<ComponentProps<typeof LandingContainer>> = async (props) => {
  await i18n.changeLanguage(props.locale ?? DEFAULT_LOCALE);
  const [faqDataResponse, categoriesMap] = await Promise.all([
    axios.get(`${REACT_APP_FAQ_WP_API_ENDPOINT}${props.locale}`),
    templateCategoriesApi.getCategoriesMap(),
  ]);

  const faqData = (faqDataResponse.data || []) as FAQTabData[];
  const shouldLoadAbTestingScript = ENABLED_AB_TESTING_PAGE_URLS.includes('/');

  return {
    props: { faqData, categoriesMap, locale: props.locale, shouldLoadAbTestingScript },
    ...(CACHE_TTL_SEC && { revalidate: CACHE_TTL_SEC }),
  };
};

export default function Landing(props: ComponentProps<typeof LandingContainer> & { locale: string }) {
  const [jsEnabled, setJsEnabled] = useState(false);
  const [isBot, setIsBot] = useState(false);
  const { t } = useTranslation();
  const { locales } = useRouter();
  const { shouldLoadAbTestingScript } = props;

  useEffect(() => {
    setJsEnabled(true);
    setIsBot(botUserAgent.test(window.navigator.userAgent));
    deleteCookie('isUnlockFlow');
  }, []);

  return (
    <>
      {shouldLoadAbTestingScript && <Head>{getAbTestingScript('variation')}</Head>}
      <OrganizationJsonLd />
      <NextSeo
        title={t('qr.page.landing.seo.title', 'QR Code Generator:  Turn any link, vCard or file into a QR code')}
        description={t(
          'qr.page.landing.seo.description',
          'QR Code Generator: convert any link, vCard, facebook, image or video into a QR code, create QR restaurant menu, track QR code scans, customize design'
        )}
        languageAlternates={languageAlternatesLinks('', locales)}
        canonical={canonicalLink('', false, i18n.language)}
      />
      <LandingContainer {...props} expandAllFAQ={!jsEnabled || isBot} />
    </>
  );
}
