import { ENABLED_AB_TESTING_PAGE_URLS } from 'constants/constants';

/**
 * This function returns the Convert script. It should be added to the <head> of the page that is included in A/B experiments (both original and variation pages).
 * It can't be a regular React component due to some strange behavior from Next where the scripts are not included when the component is rendered.
 * The `pageType` param is used to optimize performance for variation pages.
 * When `pageType` is set to `variation`, the script will load asynchronously to improve performance since on variation pages it is only used for tracking.
 * When set to `original`, to prevent flickering on original page, the script is render-blocking.
 */
export const getAbTestingScript = (pageType: 'original' | 'variation') => (
  <>
    <link rel="dns-prefetch" href="https://cdn-4.convertexperiments.com" />
    <script
      id="convert-experiments"
      type="text/javascript"
      async={pageType === 'variation'}
      src="//cdn-4.convertexperiments.com/v1/js/10048246-10049882.js?environment=production"
    />
  </>
);

export const shouldLoadAbTestingScript = (currentPath: string) => {
  const shouldLoadAbTesting = ENABLED_AB_TESTING_PAGE_URLS.includes(currentPath);

  return shouldLoadAbTesting;
};
